<template>
  <div>
    <modal
      :enabled="sheetEnabled"
      type="sheets"
      :title="'Edit privileges for role: ' + role.name"
      primary-button-text="Save"
      secondary-button-text="Cancel"
      :primary-button-disabled="primaryButtonDisabled"
      :secondary-button-disabled="false"
      @primaryClicked="onSaveClicked"
      @secondaryClicked="onCancelClicked"
      @closeModal="onCloseModal"
    >
      <label>
        Select privileges
      </label>
      <search
        v-model="searchString"
        type="rounded"
        class="product-role__search"
      />
      <div
        class="product-role__list"
      >
        <div
          v-for="priv in shownPrivileges"
          :key="priv.privilegeCode"
          class="product-role__list-item"
        >
          <checkbox
            v-model="priv.checked"
            :enabled="true"
            class="product-role__list-checkbox"
            @change="onCheckboxClicked($event.target.checked, priv)"
          />
          <div
            class="product-role__list-info"
          >
            <div
              class="product-role__list-name"
            >
              <div>
                {{ priv.name }}
              </div>
              <div
                class="product-role__list-code"
              >
                ({{ priv.hierarchyCode }})
              </div>
            </div>
            <div
              class="product-role__list-desc"
            >
              {{ priv.description }}
            </div>
          </div>
        </div>
      </div>
    </modal>
    <modal
      :enabled="promptEnabled"
      :title="promptTitle || 'Something went wrong'"
      :info="promptInfo || 'It looks like something went wrong. Please try again later.'"
      secondary-button-text="Okay"
      :styles="{ secondaryType: 'primary' }"
      @secondaryClicked="promptEnabled = false"
    />
  </div>
</template>

<script>
import {
  ref,
  watch,
  onMounted,
  computed,
} from 'vue';

import Modal from '@/components/shared/modal.vue';
import Checkbox from '@/components/shared/checkbox.vue';

import Search from '@/components/shared/search.vue';

import { useUserManagementStore } from '@/store/user-management';
import { useActorStore } from '@/store/actor';

import { storeToRefs } from 'pinia';
import { debounce, cloneDeep } from 'lodash';

import { updateRole } from '@/services/system-management';

export default {
  components: {
    Modal,
    Checkbox,
    Search,
  },

  props: {
    trigger: { // Increment to open modal (if keeping it mounted).
      type: Number,
      default: 0,
    },
    role: {
      type: Object,
      default: () => {},
      required: true,
    },
  },

  emits: ['productRolePrivilegeEdited'],

  setup(props, { emit }) {
    const {
      privileges,
      roles,
    } = storeToRefs(useUserManagementStore());

    const {
      product,
    } = storeToRefs(useActorStore());

    const sheetEnabled = ref(false); // sheet modal
    const promptEnabled = ref(false); // prompt modal
    const promptTitle = ref('');
    const promptInfo = ref('');
    const searchString = ref('');

    const selectedRole = ref(props.role);

    // Active product's privileges
    const productPrivileges = ref(null);

    // Filtered data (for search)
    const shownPrivileges = ref(null);

    // Original data
    const origPrivileges = ref();

    // Data to send
    const roleData = computed(() => ({
      productCode: product.value.code,
      name: selectedRole.value.name,
      userNotification: false,
      privilegeCodes: productPrivileges.value?.filter((priv) => priv.checked === '1').map((priv) => priv.privilegeCode),
    }));

    const primaryButtonDisabled = computed(() => {
      const origChecked = origPrivileges.value?.filter((priv) => priv.checked === '1');

      // If original and new privilege lists are of different length, button should be enabled
      if (roleData.value.privilegeCodes?.length !== origChecked?.length) {
        return false;
      }

      // Find privilegeCodes that match in original and updated role
      const matchedCodes = origChecked?.filter(
        (priv) => roleData.value.privilegeCodes.find((code) => priv.privilegeCode === code),
      );

      const sameCodes = matchedCodes?.length === origChecked?.length;

      return sameCodes;
    });

    const onSaveClicked = async () => {
      try {
        await updateRole(roleData.value);
        const index = roles.value.findIndex((r) => r.name === roleData.value.name);
        roles.value[index].privilegeCodes = roleData.value.privilegeCodes;
        emit('productRolePrivilegeEdited', roleData.value.name);
        sheetEnabled.value = false;
      } catch (err) {
        promptTitle.value = '';
        promptInfo.value = 'Unable to edit privilege(s) of role.';
        promptEnabled.value = true;
      }
    };

    const loadPrivileges = async () => {
      productPrivileges.value = privileges.value.map((priv) => ({
        ...priv,
        checked: selectedRole.value.privilegeCodes.find((code) => code.privilegeCode === priv.privilegeCode) ? '1' : '0',
      }));
      shownPrivileges.value = productPrivileges.value;
      origPrivileges.value = cloneDeep(productPrivileges.value);
    };

    const onCancelClicked = () => {
      sheetEnabled.value = false;
      loadPrivileges();
    };

    const onCloseModal = () => {
      sheetEnabled.value = false;
    };

    const onCheckboxClicked = (checked, priv) => {
      const index = productPrivileges.value.findIndex((code) => priv.privilegeCode === code.privilegeCode);
      if (checked) {
        productPrivileges.value[index].checked = '1';
      } else {
        productPrivileges.value[index].checked = '0';
      }
    };

    watch(() => props.trigger, (showSheet) => {
      sheetEnabled.value = showSheet > 0;
    });

    watch(() => searchString.value, debounce(() => {
      shownPrivileges.value = productPrivileges.value.filter((priv) => priv.name.toLowerCase()
        .includes(searchString.value.toLowerCase()));
    }, 500, {
      leading: false,
      trailing: true,
    }));

    onMounted(async () => {
      sheetEnabled.value = props.trigger > 0;
      await loadPrivileges();
    });

    return {
      privileges,
      shownPrivileges,
      searchString,
      promptEnabled,
      promptTitle,
      promptInfo,
      sheetEnabled,
      selectedRole,
      primaryButtonDisabled,
      onSaveClicked,
      onCancelClicked,
      onCloseModal,
      onCheckboxClicked,
    };
  },
};
</script>

<style scoped>
.product-role__search {
  margin-top: 0.5rem;
}

.product-role__list {
  margin-top: 0.75rem;
  border-top: 0.0625rem solid var(--colour-border-light);
}

.product-role__list-item {
  display: flex;
  align-items: center;
  padding: 1.5rem;
  border-bottom: 0.0625rem solid var(--colour-border-light);
}

.product-role__list-name {
  display: flex;
  justify-content: space-between;
}

.product-role__list-code {
  color: var(--colour-text-lightest);
}

.product-role__list-checkbox {
  margin-right: 1rem;
}

.product-role__list-info {
  display: block;
  width: 100%;
}

.product-role__list-desc {
  color: var(--colour-text-lightest);
}
</style>
