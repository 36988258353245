<template>
  <div>
    <span
      class="product-preference-types__title-button"
    >
      <h2>
        Preference types
      </h2>
    </span>
    <pre>
      {{ preferenceTypes }}
    </pre>
  </div>
</template>

<script>

// import { ref } from 'vue';

import { usePreferenceManagementStore } from '@/store/preference-management';

import { storeToRefs } from 'pinia';

export default {
  components: {
    //
  },

  props: {
    hasManagePrivilege: {
      type: Boolean,
      default: false,
    },
  },

  setup() {
    const {
      preferenceTypes,
    } = storeToRefs(usePreferenceManagementStore());

    return {
      preferenceTypes,
    };
  },
};
</script>

<style scoped>
.product-preference-types__title-button {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
  margin-bottom: 0.875rem;
}
</style>
