<template>
  <div>
    <modal
      :enabled="sheetEnabled"
      type="sheets"
      title="Add privilege"
      primary-button-text="Add"
      secondary-button-text="Cancel"
      :primary-button-disabled="primaryButtonDisabled"
      :secondary-button-disabled="false"
      @primaryClicked="onSaveClicked"
      @secondaryClicked="onCancelClicked"
      @closeModal="onCloseModal"
    >
      <FormField
        :character-limit="255"
        :should-validate="true"
        :validation="{
          msg: (!newPrivilege.privilegeCode && 'Cannot be empty.') || (!uniqueCode && 'Must be unique.'),
          id: 'code-field-error' }"
        :valid="!!newPrivilege.privilegeCode.length && uniqueCode"
      >
        <InputField
          id="add-privilege__code"
          :value="newPrivilege.privilegeCode"
          type="text"
          label="Code*"
          :helper-text="!uniqueCode ? 'Must be unique' : ''"
          :error="!uniqueCode"
          req="true"
          @input="onCodeUpdated"
        />
      </FormField>
      <FormField
        :character-limit="255"
        :should-validate="true"
        :validation="{
          msg: (!newPrivilege.name && 'Cannot be empty.') || (newPrivilege.name.length > 255 && 'Must be less than 255 characters.'),
          id: 'name-field-error' }"
        :valid="!!newPrivilege.name.length && newPrivilege.name.length <= 255"
      >
        <InputField
          id="add-privilege__name"
          :value="newPrivilege.name"
          type="text"
          label="Name*"
          req="true"
          @input="onNameUpdated"
        />
      </FormField>
      <FormField
        :character-limit="255"
        :should-validate="true"
        :validation="{
          msg: (newPrivilege.description.length > 255 && 'Must be less than 255 characters.'),
          id: 'description-field-error' }"
        :valid="newPrivilege.description.length <= 255"
      >
        <TextArea
          id="add-privilege__description"
          :value="newPrivilege.description"
          label="Description (optional)"
          @input="onDescriptionUpdated"
        />
      </FormField>
      <option-dropdown
        class="product-privilege__hierarchy-dropdown"
        :items="productHierarchies"
        :selected-item="newPrivilege.hierarchyCode"
        label="Hierarchy*"
        @itemSelected="onHierarchySelected"
      />
    </modal>
    <modal
      :enabled="promptEnabled"
      :title="promptTitle || 'Something went wrong'"
      :info="promptInfo || 'It looks like something went wrong. Please try again later.'"
      secondary-button-text="Okay"
      :styles="{ secondaryType: 'primary' }"
      @secondaryClicked="promptEnabled = false"
    />
  </div>
</template>

<script>
import {
  ref,
  computed,
  watch,
  onMounted,
  reactive,
} from 'vue';

import Modal from '@/components/shared/modal.vue';
import OptionDropdown from '@/components/shared/option-dropdown.vue';
import TextArea from '@ingka/text-area-vue';
import FormField from '@ingka/form-field-vue';
import InputField from '@ingka/input-field-vue';

import { useUserManagementStore } from '@/store/user-management';
import { useHierarchiesStore } from '@/store/hierarchy-management';
import { useActorStore } from '@/store/actor';

import { storeToRefs } from 'pinia';

import { createPrivilege } from '@/services/system-management';

export default {
  components: {
    Modal,
    InputField,
    OptionDropdown,
    TextArea,
    FormField,
  },

  props: {
    trigger: { // Increment to open modal (if keeping it mounted).
      type: Number,
      default: 0,
    },
  },

  emits: ['productPrivilegeAdded'],

  setup(props, { emit }) {
    const {
      privileges,
    } = storeToRefs(useUserManagementStore());

    const {
      addPrivilege,
    } = useUserManagementStore();

    const {
      fetchProductHierarchies,
    } = useHierarchiesStore();

    const {
      product,
    } = storeToRefs(useActorStore());

    const {
      hierarchies,
      productHierarchies,
    } = storeToRefs(useHierarchiesStore());

    const sheetEnabled = ref(false); // sheet modal
    const promptEnabled = ref(false); // prompt modal
    const promptTitle = ref('');
    const promptInfo = ref('');

    const newPrivilege = reactive({
      productCode: product.value.code,
      privilegeCode: '',
      name: '',
      description: '',
      hierarchyCode: undefined,
      userNotify: false,
    });

    const uniqueCode = computed(() => !privileges.value.find((priv) => priv.privilegeCode === newPrivilege.privilegeCode));

    const primaryButtonDisabled = computed(() => !newPrivilege.privilegeCode
      || !newPrivilege.name || !newPrivilege.hierarchyCode || !uniqueCode.value || newPrivilege.description.length > 255);

    const onSaveClicked = async () => {
      try {
        await createPrivilege(newPrivilege);
        emit('productPrivilegeAdded', newPrivilege.name);
        sheetEnabled.value = false;
        addPrivilege(newPrivilege);
      } catch (err) {
        promptTitle.value = '';
        promptInfo.value = 'Unable to create new privilege.';
        promptEnabled.value = true;
      }
    };

    const onCancelClicked = () => {
      sheetEnabled.value = false;
    };

    const onCloseModal = () => {
      sheetEnabled.value = false;
    };

    const onCodeUpdated = (event) => {
      newPrivilege.privilegeCode = event.target.value;
    };

    const onNameUpdated = (event) => {
      newPrivilege.name = event.target.value;
    };

    const onDescriptionUpdated = (event) => {
      newPrivilege.description = event.target.value;
    };

    const onHierarchySelected = (hierarchy) => {
      newPrivilege.hierarchyCode = hierarchy;
    };

    watch(() => props.trigger, (showSheet) => {
      sheetEnabled.value = showSheet > 0;
    });

    onMounted(async () => {
      const payload = {
        productCode: product.value.code,
      };
      await fetchProductHierarchies(payload);
      sheetEnabled.value = props.trigger > 0;
    });

    return {
      newPrivilege,
      privileges,
      productHierarchies,
      hierarchies,
      promptEnabled,
      promptTitle,
      promptInfo,
      sheetEnabled,
      uniqueCode,
      primaryButtonDisabled,
      onSaveClicked,
      onCancelClicked,
      onCloseModal,
      onCodeUpdated,
      onNameUpdated,
      onHierarchySelected,
      onDescriptionUpdated,
    };
  },
};
</script>

<style>
  .product-privilege__hierarchy-dropdown .dropdown__container {
    position: inherit;
  }
</style>
