<template>
  <div>
    <modal
      :enabled="sheetEnabled"
      type="sheets"
      :title="'Editing ' + privilege.privilegeCode"
      primary-button-text="Save"
      secondary-button-text="Cancel"
      :primary-button-disabled="!primaryButtonEnabled"
      :secondary-button-disabled="false"
      @primaryClicked="onSaveClicked"
      @secondaryClicked="onCancelClicked"
      @closeModal="onCloseModal"
    >
      <FormField
        :key="nameKey"
        :character-limit="255"
        :should-validate="true"
        :validation="{
          msg: !newPrivilege.name ? 'Cannot be empty.' : 'Name must be less than 255 characters.',
          id: 'code-field-error' }"
        :valid="!!newPrivilege.name && newPrivilege.name.length <= 255"
      >
        <InputField
          id="input-field-name"
          type="text"
          req="true"
          icon-position="trailing"
          :ssr-icon="nameUpdated ? arrowUTurnBackwards : []"
          icon-click-description="Undo"
          :icon-on-click="resetName"
          :value="newPrivilege.name"
          label="Name*"
          @input="onNameUpdated"
        />
      </FormField>
      <div
        class="product-privilege__description-wrapper"
      >
        <FormField
          :key="descriptionKey"
          :character-limit="255"
          :should-validate="true"
          :valid="newPrivilege.description.length <= 255"
          :validation="{
            msg: 'Name must be less than 255 characters.',
            id: 'description-field-error' }"
          class="product-privilege__description-formfield"
        >
          <TextArea
            id="product-privilege__textarea"
            :value="newPrivilege.description"
            label="Description (optional)"
            @input="onDescriptionUpdated"
          />
        </FormField>
        <div>
          <SkapaButton
            v-if="descriptionUpdated"
            :ssr-icon="arrowUTurnBackwards"
            icon-only
            type="tertiary"
            small
            class="product-privilege__button-undo"
            @click="resetDescription"
          />
        </div>
      </div>
      <option-dropdown
        class="product-privilege__hierarchy-dropdown"
        :items="productHierarchies"
        :selected-item="newPrivilege.hierarchyCode"
        label="Hierarchy"
        disabled
        @itemSelected="onHierarchySelected"
      />
    </modal>
    <modal
      :enabled="promptEnabled"
      :title="promptTitle || 'Something went wrong'"
      :info="promptInfo || 'It looks like something went wrong. Please try again later.'"
      secondary-button-text="Okay"
      :styles="{ secondaryType: 'primary' }"
      @secondaryClicked="promptEnabled = false"
    />
  </div>
</template>

<script>
import {
  ref,
  computed,
  watch,
  onMounted,
  reactive,
} from 'vue';

import Modal from '@/components/shared/modal.vue';
import OptionDropdown from '@/components/shared/option-dropdown.vue';

import TextArea from '@ingka/text-area-vue';
import FormField from '@ingka/form-field-vue';
import SkapaButton from '@ingka/button-vue';
import InputField from '@ingka/input-field-vue';
import Icons from '@ingka/ssr-icon-vue/icons';

import { useUserManagementStore } from '@/store/user-management';
import { useHierarchiesStore } from '@/store/hierarchy-management';
import { useActorStore } from '@/store/actor';

import { storeToRefs } from 'pinia';

import { updatePrivilege } from '@/services/system-management';

export default {
  components: {
    Modal,
    InputField,
    OptionDropdown,
    TextArea,
    FormField,
    SkapaButton,
  },

  props: {
    trigger: { // Increment to open modal (if keeping it mounted).
      type: Number,
      default: 0,
    },
    privilege: {
      type: Object,
      default: () => {},
    },
  },

  emits: ['productPrivilegeEdited'],

  setup(props, { emit }) {
    const {
      privileges,
    } = storeToRefs(useUserManagementStore());

    const {
      fetchProductHierarchies,
    } = useHierarchiesStore();

    const {
      product,
    } = storeToRefs(useActorStore());

    const {
      hierarchies,
      productHierarchies,
    } = storeToRefs(useHierarchiesStore());

    const sheetEnabled = ref(false); // sheet modal
    const promptEnabled = ref(false); // prompt modal
    const promptTitle = ref('');
    const promptInfo = ref('');
    const nameKey = ref(0);
    const descriptionKey = ref(0);

    const newPrivilege = reactive({
      productCode: product.value.code,
      privilegeCode: props.privilege.privilegeCode,
      name: props.privilege.name,
      description: props.privilege.description,
      hierarchyCode: props.privilege.hierarchyCode,
      userNotify: false,
    });

    const nameUpdated = computed(() => props.privilege.name !== newPrivilege.name);
    const descriptionUpdated = computed(() => props.privilege.description !== newPrivilege.description);
    const sheetFieldsUpdated = computed(() => nameUpdated.value || descriptionUpdated.value);
    const hasAllMandatoryFields = computed(() => newPrivilege.privilegeCode && newPrivilege.name && newPrivilege.hierarchyCode);

    const primaryButtonEnabled = computed(() => hasAllMandatoryFields.value && sheetFieldsUpdated.value
      && newPrivilege.description.length <= 255 && newPrivilege.name.length <= 255);

    const onSaveClicked = async () => {
      try {
        await updatePrivilege(newPrivilege);
        const index = privileges.value.findIndex((priv) => priv.privilegeCode === newPrivilege.privilegeCode);
        privileges.value[index] = newPrivilege;
        emit('productPrivilegeEdited', newPrivilege.privilegeCode);
        sheetEnabled.value = false;
      } catch (err) {
        promptTitle.value = '';
        promptInfo.value = 'Unable to update privilege.';
        promptEnabled.value = true;
      }
    };

    const onCancelClicked = () => {
      sheetEnabled.value = false;
    };

    const onCloseModal = () => {
      sheetEnabled.value = false;
    };

    const onCodeUpdated = (event) => {
      newPrivilege.privilegeCode = event.target.value;
    };

    const onNameUpdated = (event) => {
      newPrivilege.name = event.target.value;
    };

    const onDescriptionUpdated = (event) => {
      newPrivilege.description = event.target.value;
    };

    const onHierarchySelected = (hierarchy) => {
      newPrivilege.hierarchyCode = hierarchy;
    };

    const resetName = () => {
      newPrivilege.name = props.privilege.name;
      nameKey.value += 1;
    };
    const resetDescription = () => {
      newPrivilege.description = props.privilege.description;
      descriptionKey.value += 1;
    };

    watch(() => props.trigger, (showSheet) => {
      sheetEnabled.value = showSheet > 0;
    });

    onMounted(async () => {
      const payload = {
        productCode: product.value.code,
      };
      await fetchProductHierarchies(payload);
      sheetEnabled.value = props.trigger > 0;
    });

    return {
      nameKey,
      descriptionKey,
      newPrivilege,
      privileges,
      productHierarchies,
      hierarchies,
      promptEnabled,
      promptTitle,
      promptInfo,
      sheetEnabled,
      nameUpdated,
      descriptionUpdated,
      primaryButtonEnabled,
      arrowUTurnBackwards: Icons.arrowUTurnBackwards,
      onSaveClicked,
      onCancelClicked,
      onCloseModal,
      onCodeUpdated,
      onNameUpdated,
      onHierarchySelected,
      onDescriptionUpdated,
      resetName,
      resetDescription,
    };
  },
};
</script>

<style scoped>
.product-privilege__button-undo {
  margin-top: 1.5rem;
}

.product-privilege__description-wrapper {
  display: flex;
}

.product-privilege__description-formfield {
  width: 100%;
}
</style>

<style>
  .product-privilege__hierarchy-dropdown .dropdown__container {
    position: inherit;
  }
</style>
