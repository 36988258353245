<template>
  <div class="product-view grid grid-view-layout">
    <page-header
      @productsLoaded="headerLoaded=true"
    />
    <div
      v-if="!product || product.code === 'All products'"
    >
      <info-page />
    </div>
    <div
      v-else-if="!hasProductViewPrivilege.hasPrivilege"
    >
      <info-page
        title="Missing privilege"
        :description="`You do not have permission to view this page for this product (${ product?.name }).`"
      />
    </div>
    <div
      v-else-if="headerLoaded && product"
      class="product-view__content"
    >
      <h1
        class="product-view__title"
      >
        {{ product.name }}
      </h1>
      <p
        class="product-view__description"
      >
        Here you can add, remove and edit information connected to a specific product.
      </p>
      <tabs
        :tabs="tabs"
        :selected-tab="activeTab"
        class="product-view__tabs"
        @tabClicked="onTabClicked"
      />
      <product-roles
        v-if="activeTab && activeTab.name === 'Roles'"
        :has-manage-privilege="hasManagePrivilege"
      />
      <product-privileges
        v-if="activeTab && activeTab.name === 'Privileges'"
        :has-manage-privilege="hasManagePrivilege"
      />
      <product-preferences
        v-if="activeTab && activeTab.name === 'Preferences'"
        :has-manage-privilege="hasManagePrivilege"
      />
      <product-preference-types
        v-if="activeTab && activeTab.name === 'Preference types'"
        :has-manage-privilege="hasManagePrivilege"
      />
      <product-system-codes
        v-if="activeTab && activeTab.name === 'System codes'"
        :has-manage-privilege="hasManagePrivilege"
      />
    </div>
    <div
      v-else-if="headerLoaded"
    >
      <error-page
        title="Access denied"
        text="You don't have permission to access this page. Please contact support for help."
        number="403"
      />
    </div>
    <modal
      :enabled="promptEnabled"
      :title="promptTitle || 'Something went wrong'"
      :info="promptInfo || 'It looks like something went wrong. Please try again later.'"
      secondary-button-text="Okay"
      :styles="{ secondaryType: 'primary' }"
      @secondaryClicked="promptEnabled = false"
    />
  </div>
</template>

<script>
import { ref, watch } from 'vue';
import { useRouter, useRoute } from 'vue-router';

import PageHeader from '@/components/domain/page-header.vue';
import InfoPage from '@/components/shared/info-page.vue';
import ErrorPage from '@/components/shared/error-page.vue';
import Tabs from '@/components/shared/tabs.vue';
import Modal from '@/components/shared/modal.vue';

import ProductRoles from '@/components/domain/product/product-roles.vue';
import ProductPrivileges from '@/components/domain/product/product-privileges.vue';
import ProductPreferences from '@/components/domain/product/product-preferences.vue';
import ProductPreferenceTypes from '@/components/domain/product/product-preference-types.vue';
import ProductSystemCodes from '@/components/domain/product/product-system-codes.vue';

import { useUserEvaluationStore } from '@/store/user-evaluation';
import { useActorStore } from '@/store/actor';

import { storeToRefs } from 'pinia';

import { useAuth0 } from '@auth0/auth0-vue';

export default {
  components: {
    PageHeader,
    InfoPage,
    ErrorPage,
    Tabs,
    Modal,
    ProductRoles,
    ProductPrivileges,
    ProductPreferences,
    ProductPreferenceTypes,
    ProductSystemCodes,
  },

  setup() {
    const router = useRouter();
    const route = useRoute();
    const auth0 = useAuth0();
    const {
      product,
    } = storeToRefs(useActorStore());

    const {
      getHierarchyNodesForPrivilege,
    } = useUserEvaluationStore();

    const {
      hasProductViewPrivilege,
    } = storeToRefs(useUserEvaluationStore());

    const tabs = [
      { name: 'Roles' },
      { name: 'Privileges' },
      /* { name: 'Preferences' },
      { name: 'Preference types' },
      { name: 'System codes' }, */
    ];

    const activeTab = ref(tabs[0]);
    const headerLoaded = ref(false);
    const hasManagePrivilege = ref(false);
    const promptEnabled = ref(false); // prompt modal
    const promptTitle = ref('');
    const promptInfo = ref('');

    const onTabClicked = (tab) => {
      router.push({
        query: {
          ...route.query,
          tab: tab.name,
        },
      });
    };

    const loadManagePrivilege = async () => {
      hasManagePrivilege.value = false; // reset
      let privilegeCode = null;
      switch (activeTab.value.name) {
        case 'Roles':
          privilegeCode = 'ManageRoles';
          break;
        case 'Privileges':
          privilegeCode = 'ManagePrivileges';
          break;
        case 'Preferences':
        case 'Preference types':
          privilegeCode = 'ManageProductPreferences';
          break;
        case 'System codes':
          privilegeCode = null; // TODO ?
          break;
        default:
          privilegeCode = null;
      }
      if (privilegeCode) {
        const state = auth0;
        const payload = {
          productCode: 'DFP',
          hierarchyNodeType: 'PRODUCT',
          privilegeCode,
          userId: state.user.value['https://accounts.ikea.com/networkid'],
        };
        try {
          const nodes = await getHierarchyNodesForPrivilege(payload);
          hasManagePrivilege.value = product.value && nodes.hierarchyNodes
            .some((node) => node.hierarchyNodeCode === product.value.code);
        } catch (err) {
          promptTitle.value = '';
          promptInfo.value = 'Unable to verify manage privilege.';
          promptEnabled.value = true;
        }
      }
    };

    watch(() => activeTab.value, async () => {
      await loadManagePrivilege();
    }, { immediate: true });

    watch(() => product.value, async () => {
      await loadManagePrivilege();
    });

    watch(() => route.query.tab, async (tab) => {
      activeTab.value = tabs.find((t) => t.name === tab) || tabs[0];
    }, { immediate: true });

    return {
      product,
      tabs,
      activeTab,
      onTabClicked,
      headerLoaded,
      hasManagePrivilege,
      hasProductViewPrivilege,
      promptEnabled,
      promptTitle,
      promptInfo,
    };
  },
};
</script>

<style scoped>
.product-view__title {
  margin: 2.25rem 0 0.5rem 0;
}

.product-view__tabs {
  margin-top: 1.5rem;
}

.product-view__content {
  max-height: calc(100vh - 17.5rem);
}

@media (min-width: 37.5rem) {
  .product-view__title {
    margin-top: 2.25rem;
  }
}

@media (min-width: 64rem) {
  .product-view__content {
    max-height: calc(100vh - 13rem);
  }
}
</style>
