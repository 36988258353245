<template>
  <div>
    <span
      class="product-privileges__title-button"
    >
      <h2>
        Privileges
      </h2>
      <Button
        :disabled="!hasManagePrivilege"
        text="Add privilege"
        small
        :ssr-icon="plus"
        type="primary"
        @click="onAddPrivilegeClicked"
      />
    </span>
    <privileges-table
      v-if="privileges.length"
      class="product-privileges__table"
      :headers="headers"
      :rows="privileges"
      :enable-row-click="false"
    >
      <template #column-description="{ row }">
        <div
          :style="'white-space:normal'"
        >
          {{ row.description }}
        </div>
      </template>
      <template #column-delete="{ row }">
        <Button
          :disabled="!hasManagePrivilege"
          text="Delete"
          small
          :ssr-icon="trashCan"
          type="tertiary"
          @click="onDeletePrivilegeClicked(row)"
        />
      </template>
      <template #column-edit="{ row }">
        <Button
          :disabled="!hasManagePrivilege"
          text="Edit"
          small
          :ssr-icon="pencil"
          type="tertiary"
          @click="onEditPrivilegeClicked(row)"
        />
      </template>
    </privileges-table>
    <div
      v-else
    >
      No privileges to show
    </div>
    <modal
      :enabled="modalData.enabled"
      :title="modalData.title"
      :primary-button-text="'Delete'"
      secondary-button-text="Cancel"
      :styles="{type: 'danger'}"
      @secondaryClicked="() => { modalData.enabled = false }"
      @primaryClicked="onDeletePrivilegeConfirmed"
    >
      This will remove the privilege <b>{{ modalData.privilegeCode }}</b> from <b>{{ product.name }}</b>.
    </modal>
    <modal
      :enabled="errorPromptInfo.enabled"
      :title="errorPromptInfo.title || 'Something went wrong'"
      :info="errorPromptInfo.text || 'It looks like something went wrong. Please try again later.'"
      secondary-button-text="Okay"
      :styles="{ secondaryType: 'primary' }"
      @secondaryClicked="errorPromptInfo.enabled = false"
    />
    <AddProductPrivilegeSheet
      v-if="showAddPrivilegeSheet > 0"
      :trigger="showAddPrivilegeSheet"
      @productPrivilegeAdded="onProductPrivilegeAdded"
    />
    <EditProductPrivilegeSheet
      v-if="showEditPrivilegeSheet > 0"
      :privilege="selectedPrivilege"
      :trigger="showEditPrivilegeSheet"
      @productPrivilegeEdited="onPrivilegeEdited"
    />
  </div>
</template>

<script>
import {
  reactive, ref, nextTick, watch,
} from 'vue';

import AddProductPrivilegeSheet from '@/components/domain/product/add/product-privilege.vue';
import EditProductPrivilegeSheet from '@/components/domain/product/edit/product-privilege.vue';
import PrivilegesTable from '@/components/shared/table.vue';
import Modal from '@/components/shared/modal.vue';

import { useUserManagementStore } from '@/store/user-management';
import { useActorStore } from '@/store/actor';
import { useUtilsStore } from '@/store/utils';
import { useRoute } from 'vue-router';

import { deletePrivilege } from '@/services/system-management';

import Button from '@ingka/button-vue';
import Icons from '@ingka/ssr-icon-vue/icons';

import { storeToRefs } from 'pinia';

export default {
  components: {
    PrivilegesTable,
    Button,
    Modal,
    AddProductPrivilegeSheet,
    EditProductPrivilegeSheet,
  },

  props: {
    hasManagePrivilege: {
      type: Boolean,
      default: false,
    },
  },

  setup() {
    const route = useRoute();

    const {
      privileges,
    } = storeToRefs(useUserManagementStore());

    const {
      removePrivilege,
      fetchPrivileges,
    } = useUserManagementStore();

    const {
      product,
    } = storeToRefs(useActorStore());

    const {
      toastRef,
    } = useUtilsStore();

    const showEditPrivilegeSheet = ref(0); // edit privilege sheet
    const selectedPrivilege = ref();

    const headers = {
      mainHeaders: [
        { id: 'privilegeCode', displayName: 'Code' },
        { id: 'name', displayName: 'Name' },
        { id: 'description', displayName: 'Description' },
        { id: 'hierarchyCode', displayName: 'Hierarchy' },
      ],
      stickyHeaders: [
        { id: 'edit', displayName: '' },
        { id: 'delete', displayName: '' },
      ],
    };

    const modalData = reactive({
      prompt: true,
      enabled: false,
      title: 'Delete privilege?',
      primaryButtonText: 'Delete',
      secondaryButtonText: 'Cancel',
      styles: { type: 'danger' },
    });

    const errorPromptInfo = reactive({
      enabled: false,
      title: '',
    });

    const showAddPrivilegeSheet = ref(0);

    watch(() => route.query.productCode, () => {
      if (!route.query.productCode) return;
      fetchPrivileges({ productCode: route.query.productCode });
    }, { immediate: true });

    const onAddPrivilegeClicked = () => {
      showAddPrivilegeSheet.value += 1;
    };

    const onProductPrivilegeAdded = (newPrivilege) => {
      showAddPrivilegeSheet.value = 0;
      toastRef.value.toastAnimation({
        message: `The privilege ${newPrivilege} was added.`,
      });
    };

    const onEditPrivilegeClicked = async (row) => {
      if (selectedPrivilege.value?.privilegeCode !== row.privilegeCode) {
        showEditPrivilegeSheet.value = 0;
        await nextTick();
      }
      showEditPrivilegeSheet.value += 1;
      selectedPrivilege.value = row;
    };

    const onPrivilegeEdited = (privilegeCode) => {
      toastRef.value.toastAnimation({
        message: `The privilege with code ${privilegeCode} was updated.`,
      });
    };

    const onDeletePrivilegeClicked = (row) => {
      modalData.prompt = true;
      modalData.privilegeCode = row.privilegeCode;
      modalData.enabled = true;
    };

    const onDeletePrivilegeConfirmed = async () => {
      modalData.enabled = false;
      const { privilegeCode } = modalData;
      try {
        await deletePrivilege({
          productCode: product.value.code,
          privilegeCode,
        });
        toastRef.value.toastAnimation({
          message: `The privilege ${privilegeCode} was removed from ${product.value.name}.`,
        });
        removePrivilege(privilegeCode);
      } catch (err) {
        modalData.enabled = false;
        errorPromptInfo.title = '';
        errorPromptInfo.text = 'Unable to delete privilege.';
        errorPromptInfo.enabled = true;
      }
      modalData.privilegeCode = '';
      modalData.role = {};
    };

    return {
      onAddPrivilegeClicked,
      onEditPrivilegeClicked,
      onDeletePrivilegeClicked,
      onDeletePrivilegeConfirmed,
      onProductPrivilegeAdded,
      onPrivilegeEdited,
      privileges,
      product,
      modalData,
      errorPromptInfo,
      headers,
      selectedPrivilege,
      showAddPrivilegeSheet,
      showEditPrivilegeSheet,
      pencil: Icons.pencil,
      trashCan: Icons.trashCan,
      plus: Icons.plus,
    };
  },
};
</script>

<style scoped>
.product-privileges__title-button {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
  margin-bottom: 0.875rem;
}
</style>
