<template>
  <div>
    <span
      class="product-systemcodes__title-button"
    >
      <h2>
        System codes
      </h2>
    </span>
  </div>
</template>

<script>

// import { ref } from 'vue';

export default {
  components: {
    //
  },

  props: {
    hasManagePrivilege: {
      type: Boolean,
      default: false,
    },
  },

  setup() {
    return {};
  },
};
</script>

<style scoped>
.product-systemcodes__title-button {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
  margin-bottom: 0.875rem;
}
</style>
