<template>
  <div>
    <span
      class="product-roles__title-button"
    >
      <h2>
        Roles
      </h2>
      <Button
        :disabled="!hasManagePrivilege"
        text="Add role"
        small
        :ssr-icon="plus"
        type="primary"
        @click="onAddRoleClicked"
      />
    </span>
    <accordion
      v-if="rolesAndPrivileges?.length"
      id="product_roles__accordion_item"
      class="product-roles__accordion"
    >
      <accordion-item
        v-for="(role) in rolesAndPrivileges"
        :key="role.name"
        :hide-controls-when-closed="true"
        @accordionExpanded="onAccordionExpanded(role)"
      >
        <template
          #title
        >
          {{ role.name }}
        </template>
        <template
          #controls
        >
          <Button
            :disabled="!hasManagePrivilege || !!role.privilegeCodes?.length || role.hasUsers"
            text="Delete role"
            small
            :class="hasManagePrivilege && !role.privilegeCodes?.length && !role.hasUsers ?
              'product_roles__delete' : 'product_roles__delete--disabled'"
            :ssr-icon="trashCan"
            type="tertiary"
            @click.stop="onDeleteRoleClicked(role)"
          />
          <Button
            :disabled="!hasManagePrivilege"
            text="Edit privileges"
            small
            type="secondary"
            @click.stop="onEditPrivClicked(role)"
          />
        </template>
        <template
          #content
        >
          <privileges-table
            v-if="role.privilegeCodes[0]"
            id="product_roles__privileges_table"
            :headers="headers"
            :rows="role.privilegeCodes"
            :enable-row-click="false"
          >
            <template #column-delete="{ row }">
              <Button
                text="Delete"
                small
                :ssr-icon="trashCan"
                type="tertiary"
                @click="onDeletePrivilegeClicked(role, row.privilegeCode)"
              />
            </template>
          </privileges-table>
          <div
            v-show="!role.privilegeCodes.length"
            class="product_roles__no_privilege_padding"
          >
            <span>This role has no privileges.</span>
          </div>
        </template>
      </accordion-item>
    </accordion>
    <span v-else>
      No roles have been added yet.
    </span>
    <AddProductRoleSheet
      v-if="showRoleSheet > 0"
      :trigger="showRoleSheet"
      @productRoleAdded="onProductRoleAdded"
    />
    <EditProductRolePrivilegeSheet
      v-if="showEditRolePrivilegeSheet > 0"
      :role="selectedRole"
      :trigger="showEditRolePrivilegeSheet"
      @productRolePrivilegeEdited="onProductRolePrivilegeEdited"
    />
    <modal
      :title="deletePromptInfo.title"
      :enabled="deletePromptInfo.enabled"
      primary-button-text="Delete"
      secondary-button-text="Cancel"
      :styles="{type: 'danger'}"
      @secondaryClicked="deletePromptInfo.enabled = false"
      @primaryClicked="onDeleteRoleConfirmed(deletePromptInfo)"
    >
      This will delete the role <b>{{ deletePromptInfo.role }}</b> for <b>{{ product.name }}</b>.
    </modal>
    <modal
      :enabled="errorPromptInfo.enabled"
      :title="errorPromptInfo.title || 'Something went wrong'"
      :info="errorPromptInfo.text || 'It looks like something went wrong. Please try again later.'"
      secondary-button-text="Okay"
      :styles="{ secondaryType: 'primary' }"
      @secondaryClicked="errorPromptInfo.enabled = false"
    />
    <modal
      :enabled="modalData.enabled"
      :title="modalData.title"
      :primary-button-text="'Delete'"
      secondary-button-text="Cancel"
      :styles="{type: 'danger'}"
      @secondaryClicked="() => { modalData.enabled = false }"
      @primaryClicked="onDeletePrivilegeConfirmed"
    >
      This will remove <b>{{ modalData.privilegeCode }}</b> from <b>{{ modalData.role?.name }}</b>.
    </modal>
  </div>
</template>

<script>
import {
  ref, nextTick, reactive, watch, computed,
} from 'vue';

import AddProductRoleSheet from '@/components/domain/product/add/product-role.vue';
import EditProductRolePrivilegeSheet from '@/components/domain/product/edit/product-role-privilege.vue';
import Modal from '@/components/shared/modal.vue';
import Accordion from '@/components/shared/accordion.vue';
import AccordionItem from '@/components/shared/accordion-item.vue';
import PrivilegesTable from '@/components/shared/table.vue';

import { updateRole, deleteRole } from '@/services/system-management';

import { useRoute } from 'vue-router';

import { useUserManagementStore } from '@/store/user-management';
import { useActorStore } from '@/store/actor';
import { useUtilsStore } from '@/store/utils';

import { storeToRefs } from 'pinia';

import Button from '@ingka/button-vue';
import Icons from '@ingka/ssr-icon-vue/icons';

export default {
  components: {
    AddProductRoleSheet,
    EditProductRolePrivilegeSheet,
    Accordion,
    AccordionItem,
    PrivilegesTable,
    Modal,
    Button,
  },

  props: {
    hasManagePrivilege: {
      type: Boolean,
      default: false,
    },
  },

  setup() {
    const route = useRoute();

    const {
      privileges,
      roles,
    } = storeToRefs(useUserManagementStore());

    const {
      deleteRolePrivilege,
      fetchRoleUsers,
      fetchRoles,
      fetchPrivileges,
    } = useUserManagementStore();

    const {
      toastRef,
    } = useUtilsStore();

    const {
      product,
    } = storeToRefs(useActorStore());

    const headers = {
      mainHeaders: [
        { id: 'name', displayName: 'Name' },
        { id: 'hierarchyCode', displayName: 'Hierarchy Code' },
        { id: 'privilegeCode', displayName: 'Privilege Code' },
      ],
      stickyHeaders: [
        { id: 'delete', displayName: '' },
      ],
    };

    const showRoleSheet = ref(0); // role modal
    const showEditRolePrivilegeSheet = ref(0); // edit privilege sheet
    const selectedRole = ref();

    const errorPromptInfo = reactive({
      enabled: false,
      title: '',
    });

    const deletePromptInfo = reactive({
      enabled: false,
      role: '',
      title: 'Delete?',
    });

    const modalData = reactive({
      prompt: true,
      enabled: false,
      title: 'Delete privilege?',
      primaryButtonText: 'Delete',
      secondaryButtonText: 'Cancel',
      styles: { type: 'danger' },
    });

    watch(() => route.query.productCode, () => {
      if (route.query.productCode) {
        fetchRoles({ productCode: route.query.productCode });
        fetchPrivileges({ productCode: route.query.productCode });
      }
    }, { immediate: true });

    const rolesAndPrivileges = computed(() => (roles.value.map((role) => {
      console.log('rolesAndPrivileges computes');
      const newPrivilegeCodes = role.privilegeCodes?.map((pCode) => privileges.value
        .find((privilege) => privilege.privilegeCode === pCode));
      return { ...role, privilegeCodes: newPrivilegeCodes };
    })));

    const onAddRoleClicked = () => {
      showRoleSheet.value += 1;
    };

    const onProductRoleAdded = (newRole) => {
      showRoleSheet.value = 0;
      toastRef.value.toastAnimation({
        message: `${newRole} was added to roles.`,
      });
    };

    const onProductRolePrivilegeEdited = (roleName) => {
      showEditRolePrivilegeSheet.value = 0;
      toastRef.value.toastAnimation({
        message: `The role ${roleName} was updated.`,
      });
    };

    const onEditPrivClicked = (role) => {
      selectedRole.value = role;
      showEditRolePrivilegeSheet.value += 1;
    };

    const onDeletePrivilegeConfirmed = async () => {
      modalData.enabled = false;
      const { role, privilegeCode } = modalData;
      try {
        await updateRole({
          ...role,
          privilegeCodes: role.privilegeCodes.map((priv) => priv.privilegeCode).filter((code) => code !== privilegeCode),
        });
        toastRef.value.toastAnimation({
          message: `The privilege ${privilegeCode} was removed from role ${role.name}.`,
        });
        deleteRolePrivilege(role, privilegeCode);
      } catch (err) {
        modalData.enabled = false;
        errorPromptInfo.title = '';
        errorPromptInfo.text = 'Unable to delete privilege.';
        errorPromptInfo.enabled = true;
      }
      modalData.privilegeCode = '';
      modalData.role = {};
    };

    const onDeletePrivilegeClicked = (role, privilegeCode) => {
      modalData.prompt = true;
      modalData.privilegeCode = privilegeCode;
      modalData.role = role;
      modalData.enabled = true;
    };

    const onDeleteRoleClicked = (role) => {
      deletePromptInfo.enabled = true;
      deletePromptInfo.role = role.name;
    };

    const onDeleteRoleConfirmed = async () => {
      const payload = {
        productCode: product.value.code,
        roleName: deletePromptInfo.role,
      };
      try {
        await deleteRole(payload);
        deletePromptInfo.enabled = false;
        toastRef.value.toastAnimation({
          message: `The role ${deletePromptInfo.role} was deleted.`,
        });
        const index = roles.value.findIndex((r) => r.name === deletePromptInfo.role);
        roles.value.splice(index, 1);
      } catch (err) {
        deletePromptInfo.enabled = false;
        errorPromptInfo.title = '';
        errorPromptInfo.text = 'Unable to delete role.';
        errorPromptInfo.enabled = true;
      }
    };

    const onAccordionExpanded = (role) => {
      fetchRoleUsers(role, product.value.code);
    };

    const resetProductRolePrivilegeSheet = async () => {
      showEditRolePrivilegeSheet.value = 0;
      await nextTick();
      showEditRolePrivilegeSheet.value += 1;
    };

    watch(() => selectedRole.value, () => {
      resetProductRolePrivilegeSheet();
    });

    return {
      onAddRoleClicked,
      onProductRoleAdded,
      onEditPrivClicked,
      onDeletePrivilegeClicked,
      onDeletePrivilegeConfirmed,
      onProductRolePrivilegeEdited,
      onDeleteRoleClicked,
      onDeleteRoleConfirmed,
      onAccordionExpanded,
      showRoleSheet,
      showEditRolePrivilegeSheet,
      selectedRole,
      rolesAndPrivileges: computed(() => [...rolesAndPrivileges.value].sort((a, b) => a.name.toLowerCase() - b.name.toLowerCase())),
      headers,
      trashCan: Icons.trashCan,
      plus: Icons.plus,
      deletePromptInfo,
      errorPromptInfo,
      product,
      modalData,
    };
  },
};
</script>

<style scoped>
.product-roles__title-button {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
  margin-bottom: 0.875rem;
}

.product-roles__accordion {
  padding-right: 1rem;
}

.product_roles__delete {
  color: var(--colour-semantic-negative);
}

.product_roles__delete--disabled {
  color: var(--colour-disabled-ui);
}

.product_roles__delete:hover {
  text-decoration: underline;
}

.product_roles__no_privilege_padding {
  padding-bottom: 1rem;
}
</style>

<style>
#product_roles__privileges_table tr:last-child > .table__data {
  border-bottom: none;
}

#product_roles__accordion_item .accordion__item--active > .accordion__content {
  padding-bottom: 0;
}
</style>
