<template>
  <div>
    <modal
      :enabled="sheetEnabled"
      type="sheets"
      title="Add role"
      primary-button-text="Add"
      secondary-button-text="Cancel"
      :primary-button-disabled="primaryButtonDisabled"
      :secondary-button-disabled="false"
      @primaryClicked="onSaveClicked"
      @secondaryClicked="onCancelClicked"
      @closeModal="onCloseModal"
    >
      <FormField
        :character-limit="255"
        :should-validate="true"
        :validation="{
          msg: !uniqueName ? 'Name must be unique.' : !newRole.name ? 'Cannot be empty.' : 'Name must be less than 255 characters.',
          id: 'code-field-error' }"
        :valid="!!newRole.name.length && (newRole.name.length <= 255) && uniqueName"
      >
        <InputField
          id="input-field-name"
          type="text"
          req="true"
          :value="nameString"
          label="Name*"
          @input="onNameUpdated"
        />
      </FormField>
      <label>
        Select privileges
      </label>
      <search
        v-model="searchString"
        type="rounded"
        class="product-role__search"
      />
      <div
        class="product-role__list"
      >
        <div
          v-for="priv in shownPrivileges"
          :key="priv.privilegeCode"
          class="product-role__list-item"
        >
          <checkbox
            v-model="priv.active"
            :enabled="true"
            class="product-role__list-checkbox"
            @change="onCheckboxClicked($event.target.checked, priv)"
          />
          <div
            class="product-role__list-info"
          >
            <div
              class="product-role__list-name"
            >
              <div>
                {{ priv.name }}
              </div>
              <div
                class="product-role__list-code"
              >
                ({{ priv.hierarchyCode }})
              </div>
            </div>
            <div
              class="product-role__list-desc"
            >
              {{ priv.description }}
            </div>
          </div>
        </div>
      </div>
    </modal>
    <modal
      :enabled="promptEnabled"
      :title="promptTitle || 'Something went wrong'"
      :info="promptInfo || 'It looks like something went wrong. Please try again later.'"
      secondary-button-text="Okay"
      :styles="{ secondaryType: 'primary' }"
      @secondaryClicked="promptEnabled = false"
    />
  </div>
</template>

<script>
import {
  ref,
  reactive,
  computed,
  watch,
  onMounted,
} from 'vue';

import Modal from '@/components/shared/modal.vue';
import Checkbox from '@/components/shared/checkbox.vue';

import FormField from '@ingka/form-field-vue';
import InputField from '@ingka/input-field-vue';
import Search from '@/components/shared/search.vue';

import { useUserManagementStore } from '@/store/user-management';
import { useActorStore } from '@/store/actor';

import { storeToRefs } from 'pinia';
import { debounce } from 'lodash';

import { createRole } from '@/services/system-management';

export default {
  components: {
    Modal,
    Checkbox,
    InputField,
    FormField,
    Search,
  },

  props: {
    trigger: { // Increment to open modal (if keeping it mounted).
      type: Number,
      default: 0,
    },
  },

  emits: ['productRoleAdded'],

  setup(props, { emit }) {
    const { getPrivileges, addRole } = useUserManagementStore();

    const {
      roles,
    } = storeToRefs(useUserManagementStore());

    const {
      product,
    } = storeToRefs(useActorStore());

    const sheetEnabled = ref(false); // sheet modal
    const promptEnabled = ref(false); // prompt modal
    const promptTitle = ref('');
    const promptInfo = ref('');

    const nameString = ref('');
    const searchString = ref('');

    const activeProductPrivileges = ref(null);
    const shownPrivileges = ref(null);

    const newRole = reactive({
      productCode: product.value.code,
      name: '',
      userNotification: false,
      privilegeCodes: [],
    });

    const uniqueName = computed(() => !roles.value.find((role) => role.name === newRole.name));

    const primaryButtonDisabled = computed(() => !newRole.name || !uniqueName.value);

    const resetNewRole = () => {
      newRole.name = '';
      newRole.privilegeCodes = [];
      newRole.userNotification = false;
    };

    const onSaveClicked = async () => {
      try {
        await createRole(newRole);
        emit('productRoleAdded', newRole.name);
        sheetEnabled.value = false;
        addRole(newRole);
      } catch (err) {
        promptTitle.value = '';
        promptInfo.value = 'Unable to create new role.';
        promptEnabled.value = true;
      }
    };

    const onCancelClicked = () => {
      sheetEnabled.value = false;
    };

    const onCloseModal = () => {
      sheetEnabled.value = false;
    };

    const onCheckboxClicked = (checked, item) => {
      if (checked && !newRole.privilegeCodes.includes(item.privilegeCode)) {
        newRole.privilegeCodes.push(item.privilegeCode);
      } else {
        const index = newRole.privilegeCodes.findIndex((i) => item.privilegeCode === i);
        newRole.privilegeCodes.splice(index, 1);
      }
    };

    const onNameUpdated = (event) => {
      newRole.name = event.target.value;
    };

    const fetchPrivileges = async () => {
      try {
        const { privileges: p } = await getPrivileges({ productCode: product.value.code });
        activeProductPrivileges.value = p;
        shownPrivileges.value = activeProductPrivileges.value?.map((priv) => ({ ...priv, active: '0' }));
      } catch (err) {
        promptTitle.value = '';
        promptInfo.value = 'Unable to get privileges for the selected product.';
        promptEnabled.value = true;
        console.log('Unable to get privileges for the selected product.');
      }
    };

    watch(() => props.trigger, (showSheet) => {
      sheetEnabled.value = showSheet > 0;
    });

    watch(() => searchString.value, debounce(() => {
      shownPrivileges.value = activeProductPrivileges.value.filter((priv) => priv.name.toLowerCase()
        .includes(searchString.value.toLowerCase()));
    }, 500, {
      leading: false,
      trailing: true,
    }));

    onMounted(async () => {
      await resetNewRole();
      sheetEnabled.value = props.trigger > 0;
      await fetchPrivileges();
    });

    return {
      shownPrivileges,
      newRole,
      nameString,
      searchString,
      promptEnabled,
      promptTitle,
      promptInfo,
      sheetEnabled,
      uniqueName,
      primaryButtonDisabled,
      onSaveClicked,
      onCancelClicked,
      onCloseModal,
      onCheckboxClicked,
      onNameUpdated,
    };
  },
};
</script>

<style scoped>
.product-role__search {
  margin-top: 0.5rem;
}

.product-role__list {
  margin-top: 0.75rem;
  border-top: 0.0625rem solid var(--colour-border-light);
}

.product-role__list-item {
  display: flex;
  align-items: center;
  padding: 1.5rem;
  border-bottom: 0.0625rem solid var(--colour-border-light);
}

.product-role__list-name {
  display: flex;
  justify-content: space-between;
}

.product-role__list-code {
  color: var(--colour-text-lightest);
}

.product-role__list-checkbox {
  margin-right: 1rem;
}

.product-role__list-info {
  display: block;
  width: 100%;
}

.product-role__list-desc {
  color: var(--colour-text-lightest);
}
</style>
